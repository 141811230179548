// font family
$font_family: 'Open Sans', sans-serif;

// dimensions
$button_height: 34px;
$button_border_radius: 4px;
$checkbox_radio_button_height_width: 14px;
$screen_width: 1350px;
$modal_footer_height: 50px;

// font sizes
$font_size: 14px;
$font_size_15: 15px;
$font_size_18: 18px;
$font_size_26: 26px;

// COLORS
$link_color: #27a6a4;
$font_color: #454250;
$mandatory_color: #27a6a4;
$light_color_text: #83878c;
$placeholder_color: #c1c1c1;
$body_bg_color: #b8d4de;
$border_color: #cfdde6;
$tooltip_background_color: #fbfefe;
$scrollbar_bg_color: #454250;
$error_border_color: #c75439;
$disabled_bg_color: #eff3f6;
$alert_bg: #f8e9e6;

// Table
$table_header_bg: #e5eaef;

// upload file colors
$dashed_border_color: #74798f;
$drag_drop_bg_color: #f9f9f9;
$upload_file_image_color: #c0c0c0;
$uploaded_file_border_color: #ddd;

// button colors
$button_primary_top_color: #379392;
$button_primary_bottom_color: #2b7272;
$button_secondary_bottom_color: #e5f2f4;

// tab colors - font, bg and border
$active_tab_color: #27a6a4;
$active_tab_bg_color: #fff;
$inactive_tab_bg_color: #e5eaef;
$inactive_tab_color: #716E79!important;
$nav_color: #454250!important;
$inactive_tab_border_color: #d0dde6;
$checkbox_border_color: #B2B0B6;

// loader color
$loader-color: #1a5a73;
