@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';
@import './theme/styling-variables';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
	box-sizing: border-box;
	outline: none !important;
}

body,
html {
	color: $font_color !important;
	font-family: $font_family !important;
	font-size: $font_size !important;
	font-style: normal !important;
	font-weight: normal !important;
	margin: 0;
}

body {
	background-color: $body-bg-color !important;
	// // Hiding ScrollBar - Google Chrome
	// &::-webkit-scrollbar {
	//   display: none;
	// }
	// -ms-overflow-style: none; /* Hiding ScrollBar - IE and Edge */
	// scrollbar-width: none; /* Hiding ScrollBar - Firefox */
}
// font size classes
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

.heading {
	font-size: 26px;
	line-height: 30px;
}

.font-15 {
	font-size: 15px;
	line-height: 18px;
}

.font-16 {
	font-size: 16px;
	line-height: 19px;
}

.font-14 {
	font-size: 14px;
	line-height: 18px;
}

.font-18 {
	font-size: 18px;
	line-height: 21px;
}

.font-22 {
	font-size: 22px;
	line-height: 24px;
}

.font-24 {
	font-size: 24px;
	line-height: 28px;
}

.font-26 {
	font-size: 26px;
	line-height: 32px;
}

.link-color {
	color: $link_color;
}

.txt-ellps {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100% !important;
}

// button css
.button-primary {
	background: #27a6a4;
	border: none;
	border-radius: $button_border_radius;
	color: white;
	height: $button_height;
	padding: 7px 20px;

	.fa {
		color: white;
		font-size: 15px;
		margin-right: 5px;
	}

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
}

.button-secondary {
	background: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: $button_border_radius;
	color: #454250;
	height: $button_height;
	padding: 7px 20px;
	line-height: 14px;

	.fa {
		color: #1a1628;
		font-size: 15px;
		margin-right: 5px;
	}

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
}

.custom-footer {
  align-items: center;
  border-top: 1px solid #cfdde6;;
  -webkit-box-align: center;
  -webkit-box-pack: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
}

.ctemp-footer {
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}


.custom-input.date-input {
  background: $white url('./assets/images/icon_cal.svg') right no-repeat;
  background-position: calc(100% - 0.5rem);
  cursor: pointer;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  width: 130px;
}

.btn-secondary-white {
	background: white;
	border: 1px solid white;
	border-radius: $button_border_radius;
	color: $link_color;
	height: $button_height;
	padding: 7px 20px;
}

// scrollbar

/* width */
::-webkit-scrollbar {
	height: 8px;
	width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $scrollbar_bg_color;
	border-radius: 4px;
}

.light-text {
	color: $light_color_text;
}

::placeholder {
	color: $placeholder_color;
}

// input box styling
input {
	border: 1px solid $border_color;
	color: $font_color;
	height: $button_height;
	padding: 8px;

	&:disabled {
		background-color: #eff3f6;
	}
}

.black {
	color: $font_color !important;
}

.error {
	border: 1px solid $error_border_color !important;
}

::ng-deep .error-parent {
	background-color: #f8e9e6 !important;
	background: #f8e9e6 !important;
}

.error-required {
	background-color: rgba($alert_bg, 0.5) !important;
}

// input type number
input::-ms-reveal,
input::-ms-clear {
	display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.required {
	border-left: 4px solid $mandatory_color;
}
sup {
	color: #27a6a4;
}

//checkbox button
.custom-checkbox-button {
	input {
		display: none;
	}

	span {
		background-color: white;
		border: 1px solid $border_color;
		display: inline-block;
		height: $checkbox_radio_button_height_width;
		margin-right: 5px;
		position: relative;
		width: $checkbox_radio_button_height_width;
	}
}
input.custom-checkbox {
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #cfdde6;
    height: 14px;
    width: 14px;

    &:checked {
     background-image: url('../src/assets/images/correct.svg');
      background-repeat: no-repeat;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

[type='checkbox']:checked + span::before {
	color: $active_tab_color;
	content: '\2714';
	font-size: 12px;
	left: 1px;
	position: absolute;
	top: -3px;
}

// radio button
.custom-radio-button {
	cursor: pointer;
	display: block;
	position: relative;

	input {
		display: none;
	}

	span {
		background-color: white;
		border: 1px solid $active_tab_color;
		border-radius: 50%;
		height: $checkbox_radio_button_height_width;
		margin-right: 5px;
		position: relative;
		width: $checkbox_radio_button_height_width;
		// &:after {

		// }
	}
}

[type='radio']:checked + span::before {
	background: $active_tab_color;
	border-radius: 50%;
	content: '';
	display: block;
	height: 7px;
	left: 20%;
	position: absolute;
	top: 20%;
	width: 7px;
}

// NAVIGATION CSS
.navbar-nav {
	height: 40px;
}

.collapse.navbar-collapse {
	border-bottom: 1px solid $inactive_tab_border_color;
}

.nav-link {
	background-color: $inactive_tab_bg_color;
	border-left: 1px solid $inactive_tab_border_color;
	border-right: 1px solid $inactive_tab_border_color;
	border-top: 1px solid $inactive_tab_border_color;
	color: $nav_color;
	margin-right: 8px;
	padding: 11px 20px !important;

	&:hover {
		color: $inactive_tab_color;
	}
}

// .active {
// 	background: white !important;
// 	border-bottom: none;
// 	// color: $active_tab_color !important;
// 	cursor: default;
// 	// font-weight: bold !important;
// 	// height: 41px;
// }

// Search Bar
.search-bar {
	align-items: center;
	display: inline-flex;
	margin-bottom: 8px;

	p {
		margin-right: 30px;
	}

	.input-field {
		border: 1px solid $border_color;
		height: $button_height;
		padding: 8px;

		input {
			border: none;
			height: unset;
			padding: 0;
		}
		.fa-search {
			color: #1a1628;
		}
	}
}

// DATE PICKER
.date-picker {
	border: 1px solid $border_color;
	height: 35px;

	input {
		border: none;
	}

	.fa {
		color: $link_color;
		font-size: 20px;
	}
}

.container {
	max-width: calc(100vw - 0%) !important;
	padding-left: 80px;
	//margin: 0px;
}

//@media (min-width: 992px) {
//
//}
.border-bottom,
.border-top,
.border-left,
.border-right,
.border {
	border-color: $border_color !important;
}

.cursor-pointer {
	cursor: pointer;
  margin-top: 3px;
}

// modal styling
.modal-content {
	border-radius: 0 !important;
}

.modal-footer {
	font-size: $font_size;
	height: $modal_footer_height;
	padding: 8px 15px;

	.button-primary {
		margin: 0;
	}

	.fa {
		color: $link_color;
		font-size: 18px;
	}

	div {
		color: $link_color;
	}
}

// site level study data modal
$slsd_width: 1144px;
$slsd_modal_maxheight: 500px;
$slsd_modal_minheight: 300px;

.slsdClass {
	.modal-dialog {
		max-width: $slsd_width;
		min-width: $slsd_width;
		position: relative;

		.modal-body {
			max-height: $slsd_modal_maxheight;
			height: $slsd_modal_maxheight;
			min-height: $slsd_modal_minheight;
		}
	}
}

.studyDetailsInfo-popover {
	border-radius: 4px;
	min-width: 520px;

	.popover-body {
		max-width: 580px !important;
		min-width: 580px !important;

		h4 {
			color: $font_color !important;
			font-size: 21px !important;
		}

		.item-block {
			label,
			p {
				color: $font_color !important;
				font-size: 14px !important;
				line-height: 16px !important;
			}

			label {
				font-weight: bold !important;
				word-break: break-word !important;
			}
		}

		#studyName {
			max-width: 450px !important;
		}

		#tArea {
			max-width: 120px !important;
		}

		#indication {
			max-width: 100px !important;
		}

		#phase {
			max-width: 100px !important;
		}

		.popup-container {
			border-bottom: 1px solid #ddd;
			display: flex;
			margin-bottom: 15px;
			padding-bottom: 15px;
		}

		.popup-container:nth-child(2) {
			align-items: baseline;
			// height: auto;
			justify-content: center;
			padding-bottom: 10px;
		}

		.popup-container:nth-child(3) {
			border: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

.tooltip-class .tooltip-inner {
	align-items: center;
	background-color: $tooltip_background_color;
	border: 1px solid $border_color;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
	color: black;
	display: flex;
	min-height: $button_height;
	min-width: 250px;
	text-align: left;
	z-index: 99999;
}

// .bs-tooltip-auto[x-placement^='top'] .arrow,
// .bs-tooltip-top .arrow {
// 	bottom: 0.1rem;
// }

// .bs-tooltip-auto[x-placement^='top'] .arrow::bottom,
// .bs-tooltip-top .arrow::bottom {
// 	border-width: 0.6rem 0.6rem 0;
// }
.tooltip-no-min-width .tooltip-inner {
	min-width: auto !important;
}

.tooltip.show {
	opacity: 1;
}

.bs-tooltip-right .arrow::before {
	border-right-color: $inactive_tab_color !important;
}

.bs-tooltip-left .arrow::before {
	border-left-color: $inactive_tab_color !important;
}

.bs-tooltip-top .arrow::before {
	border-top-color: $inactive_tab_color !important;
}

.bs-tooltip-bottom .arrow::before {
	border-bottom-color: $inactive_tab_color !important;
}

.loading-circle {
	align-items: center;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;

	img {
		-webkit-animation: rotation 2s infinite linear;
	}

	p {
		margin-top: 5px;
	}
}

.loading-enrollemnt img {
	-webkit-animation: rotation 2s infinite linear;
}

.loader {
	height: 100%;
	width: 100%;

	i {
		color: $loader-color;
		font-size: 2.5rem;
	}
}

.width-fit {
	width: fit-content;
}

.lowerModellingTrackMonitor .modal-dialog {
	max-width: 700px !important;
	min-width: 700px !important;
	top: 55px !important;

	@media (min-width: 1200px) {
		top: -15px !important;
	}
	@media (min-width: 1350px) {
		top: 25px !important;
	}
	@media (min-width: 1500px) {
		top: 55px !important;
	}

	.modal-body {
		padding-bottom: 0;
		padding-top: 0;
	}
}

.refreshForecast .modal-dialog {
	left: 50% !important;
	max-width: 500px !important;
	min-width: 500px !important;
	position: absolute !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;

	.modal-body {
		padding-bottom: 0;
		padding-top: 0;
	}
}

.cloneModalClass .modal-dialog {
	left: 50% !important;
	max-width: 500px !important;
	min-width: 500px !important;
	position: absolute !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;

	.modal-body {
		padding-bottom: 0;
		padding-top: 0;
	}
}

#simulation-input-params .accordion-container {
	min-height: calc(100vh - 425px) !important;
	max-height: calc(100vh - 425px) !important;
	overflow-y: scroll;

	.accordion-body{
		border: 1px solid #ddd !important;
		padding: 10px !important;
		.card {
			.card-body{
				padding: 0px 0px !important;
			}
		}
	}
}

.addGeoClass {
	.modal-body {
		height: 150px !important;

		.popup-dropdown {
			max-width: 240px !important;
		}
	}

	.modal-dialog {
		display: flex !important;
		flex-direction: column !important;
		justify-content: center !important;
		min-height: calc(100vh - 60px) !important;
		overflow-y: auto !important;
	}
	@media (max-width: 767px) {
		.modal-dialog {
			min-height: calc(100vh - 20px) !important;
		}
	}
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(359deg);
	}
}

::ng-deep .modelling-predictions.show{
	top: 200px !important;
}

::ng-deep .toast-container {
	position: fixed;
	top: 92px;
	right: 25px;
  background: #27a6a4;
	color: white;
	//padding: 10px;
	min-width: 0px;
	max-width: 320px;
	z-index: 600;
}

.toast-info{
	padding: 10px;
}

.studyClass {
	.modal-dialog {
		max-width: 500px !important;
	}
}

.studyClassRefresh {
	.modal-dialog {
		max-width: 700px !important;
	}
}
